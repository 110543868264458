<template>
  <!-- TOP Nav Bar -->
  <header id="main-header">
    <div class="main-header">
      <b-container fluid>
        <b-row>
          <b-col sm="12">
            <nav class="navbar navbar-expand-lg navbar-light p-0">
              <a
                v-if="categories?.length"
                class="navbar-toggler c-toggler collapsed"
                href="javascript:void(0)"
                @click="openSidebar"
              >
                <div class="navbar-toggler-icon" data-toggle="collapse">
                  <span class="navbar-menu-icon navbar-menu-icon--top"></span>
                  <span
                    class="navbar-menu-icon navbar-menu-icon--middle"
                  ></span>
                  <span
                    class="navbar-menu-icon navbar-menu-icon--bottom"
                  ></span>
                </div>
              </a>

              <router-link
                v-if="sidebar == false && !isDesktopSize"
                class="navbar-brand"
                to="/"
              >
                <img
                  alt="blend box logo"
                  class="img-fluid logo"
                  height="10"
                  size="202"
                  src="/blendBoxLogo.svg"
                  width="10px"
                />
              </router-link>

              <!--show on mobile  -->
              <div v-show="sidebar == false && isDesktopSize == false">
                <div class="navbar-right mx-4">
                  <div v-if="$i18n.locale == 'en' && !isLoading">
                    Points : {{ userPoints }}
                  </div>
                  <div v-if="$i18n.locale == 'ku' && !isLoading">
                    خاڵەکانم : {{ userPoints }}
                  </div>
                  <div v-if="$i18n.locale == 'ar' && !isLoading">
                    نقاط : {{ userPoints }}
                  </div>
                </div>

                <div
                  v-show="sidebar == false && isDesktopSize == false"
                  class="dropdown"
                >
                  <button class="dropbtn" @click="showLang = !showLang">
                    <span style="font-size: 18px">
                      <span v-if="$i18n.locale == 'en'">English</span>
                      <span v-if="$i18n.locale == 'ku' || $i18n.locale == null">
                        کوردی
                      </span>
                      <span v-if="$i18n.locale == 'ar'"> عربی </span>
                      <i class="fas fa-caret-down"></i>
                    </span>
                  </button>
                  <div
                    v-show="sidebar == false && showLang"
                    class="dropdown-content"
                  >
                    <span @click="changeLang('en')">English</span>
                    <span @click="changeLang('ku')">کوردی</span>
                    <span @click="changeLang('ar')">عربی</span>
                  </div>
                </div>
              </div>
              <!--  -->

              <div v-if="isDesktopSize" class="d-flex align-items-center">
                <div v-show="sidebar == false" class="navbar-right mx-4">
                  <div v-if="$i18n.locale == 'en'">
                    Points : {{ userPoints }}
                  </div>
                  <div v-if="$i18n.locale == 'ku'">
                    خاڵەکانم : {{ userPoints }}
                  </div>
                  <div v-if="$i18n.locale == 'ar'">نقاط : {{ userPoints }}</div>
                </div>

                <div v-show="sidebar == false" class="dropdown">
                  <button class="dropbtn" @click="showLang = !showLang">
                    <span style="font-size: 18px">
                      <span v-if="$i18n.locale == 'en'">English</span>
                      <span v-if="$i18n.locale == 'ku' || $i18n.locale == null">
                        کوردی
                      </span>
                      <span v-if="$i18n.locale == 'ar'"> عربی </span>
                      <i class="fas fa-caret-down"></i>
                    </span>
                  </button>
                  <div
                    v-show="sidebar == false && showLang"
                    class="dropdown-content"
                  >
                    <span @click="changeLang('en')">English</span>
                    <span @click="changeLang('ku')">کوردی</span>
                    <span @click="changeLang('ar')">عربی</span>
                  </div>
                </div>
              </div>

              <b-collapse id="navbarSupportedContent" :visible="sidebar" is-nav>
                <div class="menu-main-menu-container">
                  <ul id="top-menu" class="navbar-nav ml-auto" dir="rtl">
                    <li
                      v-if="$route.name !== 'main-category'"
                      class="menu-item"
                    >
                      <a v-if="$i18n.locale == 'en'" @click="gotoPage('/')">
                        Home
                      </a>
                      <a v-if="$i18n.locale == 'ku'" @click="gotoPage('/')">
                        سەرەکی
                      </a>
                      <a v-if="$i18n.locale == 'ar'" @click="gotoPage('/')">
                        الرئيسية</a
                      >
                    </li>
                    <li
                      v-for="(item, index) in categories"
                      :key="index"
                      class="menu-item"
                    >
                      <a
                        @click="
                          gotoPage(
                            '/category/' +
                              item.id +
                              '/' +
                              $route.params.categoryId
                          )
                        "
                        >{{ item.name?.[$i18n.locale] }}</a
                      >
                    </li>
                    <li
                      v-if="
                        $route.name !== 'main-category' &&
                        $route.params.categoryId
                      "
                      class="menu-item mx-3"
                    >
                      <a
                        @click="
                          gotoPage(`/myVideos/${$route.params.categoryId}`)
                        "
                      >
                        {{
                          $i18n.locale == "en"
                            ? "My Content"
                            : $i18n.locale == "ku"
                            ? "لیستەکەم"
                            : "محتوياتي"
                        }}
                      </a>
                    </li>

                    <!--                    <li class="menu-item">-->
                    <!--                      <a-->
                    <!--                        v-if="$i18n.locale == 'en'"-->
                    <!--                        @click="gotoPage('/ramadan')"-->
                    <!--                        >Ramadan</a-->
                    <!--                      >-->
                    <!--                      <a-->
                    <!--                        v-if="$i18n.locale == 'ku'"-->
                    <!--                        @click="gotoPage('/ramadan')"-->
                    <!--                        >ڕەمەزان</a-->
                    <!--                      >-->
                    <!--                      <a-->
                    <!--                        v-if="$i18n.locale == 'ar'"-->
                    <!--                        @click="gotoPage('/ramadan')"-->
                    <!--                        >رمضان</a-->
                    <!--                      >-->
                    <!--                    </li>-->
                  </ul>
                </div>
              </b-collapse>

              <router-link
                v-if="sidebar == false && isDesktopSize"
                class="navbar-brand"
                to="/"
              >
                <img
                  alt="blend box logo"
                  class="img-fluid logo"
                  height="10"
                  size="202"
                  src="/blendBoxLogo.svg"
                  width="10px"
                />
              </router-link>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </header>
  <!-- TOP Nav Bar END -->
</template>
<script>
import { APPLOGONAME, core } from "../../../config/pluginInit"
import ApiService from "../../../services/api"

export default {
  name: "FrontendNav",
  props: {
    logo: {
      type: String,
      default: require("../../../assets/myreligion_logo.svg")
    },
    items: { type: Array },
    userprofile: { type: String }
  },
  data() {
    return {
      appName: APPLOGONAME,
      sidebar: false,
      categories: [],
      isSelectLanguage: true,
      showLang: false,
      isLoading: false,
      isDesktopSize: false,
      points: 0
    }
  },
  mounted() {
    this.$i18n.locale = localStorage.getItem("lang") ?? "ku"
    core.index()
    if (this.$route.params.categoryId) {
      this.isLoading = true
      this.getCategories(this.$route.params.categoryId)
    }
    this.checkScreenSize()
    // Listen for window resize events to update isDesktopSize
    window.addEventListener("resize", this.checkScreenSize)
  },
  methods: {
    checkScreenSize() {
      // Update isDesktopSize based on screen width
      this.isDesktopSize = window.innerWidth >= 768 // Adjust the breakpoint as needed
    },
    changeLang(lang) {
      this.showLang = false
      this.$i18n.locale = lang
      localStorage.setItem("lang", lang)
      ApiService.changeLAng()
      this.$http.defaults.headers.common["lang"] = localStorage.getItem("lang")
    },
    gotoPage(link) {
      document.getElementsByTagName("body")[0].classList.remove("nav-open")
      this.sidebar = false
      this.$router.push(link)
    },
    openSidebar() {
      if (this.sidebar === false) {
        document.getElementsByTagName("body")[0].classList.add("nav-open")
        this.sidebar = true
      } else {
        document.getElementsByTagName("body")[0].classList.remove("nav-open")
        this.sidebar = false
      }
    },
    getCategories() {
      if (this.$route.params.categoryId) {
        this.isLoading = true
        ApiService.getCategories(this.$route.params.categoryId)
          .then(response => {
            this.categories = response.data.data
            this.points = response.data.user_points
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  },
  computed: {
    userPoints() {
      return this.$store.state.points
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.showLang = false
    }
  }
}
</script>
<style>
#top-menu {
  text-align-last: right !important;
}

.dropbtn {
  border: none;
  background-color: transparent;
  color: #f0eeee;

  @media (min-width: 1024px) {
    margin-top: 0px;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f8f8f8;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(145, 42, 42, 0.2);
  z-index: 1;
  right: -13px;
}

.dropdown-content span {
  color: rgb(20, 20, 20);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.dropdown-content span:hover {
  background-color: v-bind(primaryColor);
  color: #ffffff;
  transition: 0.3s;
  background-color: var(--iq-primary-orange);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  transition: 0.5s;
  background-color: v-bind(primaryColor);
}

@media (max-width: 768px) {
  .navbar-right {
    font-size: 13px;
  }

  #top-menu {
    text-align-last: left !important;
  }
}
</style>
